import { Injectable, TemplateRef, Type } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { upperFirst } from 'lodash';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RootState } from '../root-store/root-state';

@Injectable({
  providedIn: 'root',
})
export class WarningConfirmModalService {
  private modal?: NzModalRef;

  constructor(
    private store: Store<RootState>,
    private modalService: NzModalService,
    private translate: TranslateService,
  ) {}

  open<T = {}>(options: {
    message?: string;
    action?: Action;
    labelAction?: string;
    labelDiscardAction?: string;
    discardAction?: Action;
    content?: string | TemplateRef<any> | Type<unknown>;
    contentParams?: T;
    footerButtons?: Array<{
      label: string;
      type?: 'primary' | 'default';
      onClick: () => void;
    }>;
  }): Observable<'cancel' | 'save'> {
    const {
      message,
      action,
      labelAction,
      labelDiscardAction,
      discardAction,
      content,
      contentParams,
      footerButtons,
    } = options;

    this.modal = this.modalService.create({
      nzTitle: upperFirst(this.translate.instant('confirm_required')),
      nzContent: content || upperFirst(message),
      nzData: contentParams,
      nzOnCancel: () => {
        if (discardAction) {
          this.store.dispatch(discardAction);
        }
        this.modal.close();
      },
      nzFooter: footerButtons?.length
        ? footerButtons
        : [
            {
              label: upperFirst(
                this.translate.instant(labelDiscardAction || 'cancel'),
              ),
              onClick: () => {
                if (discardAction) {
                  this.store.dispatch(discardAction);
                }
                this.modal.close();
              },
            },
            {
              label: upperFirst(
                this.translate.instant(labelAction || 'ignore_and_continue'),
              ),
              type: 'primary',
              onClick: () => {
                if (action) {
                  this.store.dispatch(action);
                }

                this.modal.close('save');
              },
            },
          ],
    });

    return this.modal.afterClose
      .asObservable()
      .pipe(map((result) => (result === 'save' ? 'save' : 'cancel')));
  }

  closeModal() {
    this.modal.close();
  }
}
